<template>
  <div>功能完善中..</div>
</template>

<script>
export default {
  name: "PageOther"
}
</script>

<style scoped>

</style>